import { motion } from "framer-motion";
import Indigie from "../Indigie.png"
import Salish from "../Salish.png"
import Lumminade from "../Lumminade.png"

export default function Sodas() {
  return (
    <div className="bg-[#1d1d1d] text-white">
      <section className="relative w-full py-32 px-6 md:px-12 lg:px-20 text-center">
        
        <div className="relative w-full flex flex-col justify-center items-center text-white pb-12">
          <motion.div 
            className="relative text-lg md:text-xl font-semibold text-red tracking-wide mb-2"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p className="mt-28">Introducing our</p>
          </motion.div>
          <h1
            id="rust" 
            className="relative text-6xl md:text-6xl font-medium"
          >
            Soda Syrups
          </h1>
          <p className="relative text-lg md:text-xl text-gray-600 max-w-3xl mt-4">
          Great for mocktails, cocktails, sodas, teas, marinades, dressings & desserts.  We invite you to mix Natoncks Metsu handmade syrups into your own specialty cocktail creation, or mix with your favorite sparkling water.<br></br><br></br>

          These indigenous crafted syrups (and many more) have been featured in the form of handmade sodas at exclusive events and Pow-Wows in the PNW for years and are now available beyond the borders of Indigenous Country for your customers to Enjoy!
          </p>
        </div>

        <div className="max-w-6xl mx-auto pt-12">
          <h2  className="text-3xl md:text-4xl font-semibold text-center mb-6">Featured Syrups</h2>
          
          <div className="flex flex-wrap justify-center lg:px-0 md:px-2 px-8 sm:px-10 gap-8">
            
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center w-full md:w-[48%] lg:w-[30%]">
              <div className="w-full h-auto overflow-hidden rounded-md">
                <img src={Indigie} alt="Indigie Koolaid" className="w-full h-full object-cover" />
              </div>
              <h3 className="text-xl font-semibold mt-4">Indigie Koolaid</h3>
              <p className="text-gray-700 mt-2">Dried Hibiscus Flowers steeped in fresh pressed lime juice simple syrup</p>
              <p className="text-gray-600 mt-1 italic text-sm">Ingredients: dried hibiscus flowers, fresh pressed lime juice, sugar</p>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center w-full md:w-[48%] lg:w-[30%]">
              <div className="w-full h-auto overflow-hidden rounded-md">
                <img src={Salish} alt="Salish Style" className="w-full h-full object-cover" />
              </div>
              <h3 className="text-xl font-semibold mt-4">Salish Style</h3>
              <p className="text-gray-700 mt-2">Hand harvested coast salish wild young cedar tips steeped in fresh pressed tangerine juice simple syrup.</p>
              <p className="text-gray-600 mt-1 italic text-sm">Ingredients: fresh pressed tangerine juice, sugar, young fresh cedar tips</p>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center w-full md:w-[48%] lg:w-[30%]">
              <div className="w-full h-auto overflow-hidden rounded-md">
                <img src={Lumminade} alt="Lumminade" className="w-full h-full object-cover" />
              </div>
              <h3 className="text-xl font-semibold mt-4">Lumminade</h3>
              <p className="text-gray-700 mt-2">Fresh Mint & Rosemary steeped in fresh pressed lemon juice simple syrup. Named Lumminade from its initial service at the 2019 paddle to Lummi.</p>
              <p className="text-gray-600 mt-1 text-sm">Ingredients: fresh pressed lemon juice, sugar, rosemary, mint
              </p>
            </div>

          </div>
        </div>

          <h2 className="text-3xl font-bold mt-16">Now Available for Wholesale</h2>
        
          <p className="mx-auto relative text-lg md:text-xl text-gray-600 max-w-3xl mt-4">
          NATONCKS Metsu is proud to offer our handcrafted syrups, made fresh in South Seattle using traditional Indigenous plant medicines and fresh fruit juices. These syrups are perfect for enhancing your beverage menu or offering directly to customers who want to create their own cocktails and handmade sodas. <br></br><br></br>
          Ideal for retailers, specialty grocery stores, restaurants, coffee shops, food trucks, and small grocers, our syrups bring unique, high-quality flavors to any selection.<br></br><br></br>
          For more information, email us or fill out the form below.

          </p>
   
      
          <div className="w-full pt-8">

          <iframe 
          className="w-full h-[1000px]" 
          src="https://docs.google.com/forms/d/e/1FAIpQLSfRfZGz9PFqFJqj3UJU_nCMAntlPvNszzokEU0LvnIY0fmXFQ/viewform?embedded=true" 
          frameBorder="0"
        >
          Loading…
        </iframe>

</div>

  


      </section>
    </div>
  );
}
