import summer from "../SummerMenu.png"
import MultiMenu from '../Multi-Menu.png'

import beef from '../BeefMenu4.png'
import chicken from '../ChickenMenu.png'
import salmon2 from '../SalmonMenu2.png'
import Smokey from '../SmokeySalmonMenu.png'
import salmon3 from '../SalmonMenu5.png'



export default function WinterMenu() {
    return (
        <>
    <div class="py-40 text-center ">
    <h2 id="rust" className="mt-10 lg:mt-28 md:text-6xl tracking-tight text-gray-900 text-4xl">Sample Menus</h2>
    <div class="">
    
    <div id="height" class="  text-center   ">
      
    <div class="grid md:grid-cols-3 grid-cols-1   py-4 px-6 ">

            <div class="mb-10">
         
            <img
                  src={beef}
                  alt="Beef Menu"
                  className="object-contain lg:px-0 lg:w-3/4 mb-8   justify-center m-auto mt-12 px-8"
                />    

            <a
              href="BeefMenu2025.pdf" target="_blank"
              className="rounded-md m-4 mx-8 md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
          
              Download Beef Menu
            </a>
            </div>    
            
            <div class="mb-10">
            
            <img
                  src={chicken}
                  alt="Chicken Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0   justify-center m-auto mt-12 px-8 "
                />  

            <a
              href="ChickenMenu2025.pdf" target="_blank"
              className="rounded-md m-4 mx-8  md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
            
              Download Chicken Menu 
                         </a>
            </div>
      

            <div class="mb-10">
            
            <img
                  src={salmon3}
                  alt="Salmon Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0  justify-center m-auto mt-12 px-8 "
                />  
            <a
              href="SalmonMenu2025.pdf" target="_blank"
              className="rounded-md m-4 mx-8 nowrap md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            > 

           
              Download Salmon Menu 
                         </a>
            </div>


            <div class="mb-10">
            
            <img
                  src={Smokey}
                  alt="Smokey Salmon Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0  justify-center m-auto mt-12 px-8 "
                />  
            <a
              href="SmokeySalmonMenu2025.pdf" target="_blank"
              className="rounded-md m-4 mx-8 nowrap md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            > 

           
              Download Smokey Salmon Menu 
                         </a>
            </div>


            <div class="mb-10">
            
            <img
                  src={MultiMenu}
                  alt="Sample Multi-Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0   justify-center m-auto mt-12 px-8 "
                />  

            <a
              href="Multi-Menu2025.pdf" target="_blank"
              className="rounded-md m-4 mx-8  md:mx-2 bg-blue px-3.5 py-2.5 lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
           
              Download Sample Multi-Menu
                         </a>
            </div>
        
        </div>
        
        


        </div>
        
        



    </div>
        
        

            <div className="mt-12 -mb-28  md:grid md:grid-cols-3 -gap-4	grid sm:grid-none    ">
            <a
              href="/AppetizerMenu"
              className="rounded-md m-4 mx-auto md:mx-auto bg-red px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Appetizer Menu
            </a>

            <a
              href="/Catering" target="_blank"
              className="rounded-md m-4 mx-auto px-3.5 py-2.5 text-m text-black  hover:text-red lg: transition duration-700 hover:text-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Back to catering request
            </a>
          
          <a
              href="/BeverageMenu" 
              className="rounded-md m-4 mx-auto bg-beige px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black lg: transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Beverage Menu
            </a>
            
          </div>
        </div>
        
        
        </>






    )
}